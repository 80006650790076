
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  filter: blur(4px);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    animation-direction: alternate;
  }
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: black;
}

.code-container {
  display: flex;
  justify-content: center;
  margin: 1em;
  overflow: auto;
}

.code {
  color: black;
  text-align: left;
  overflow: auto;
  height: 300px;
  font-size: 10px;
}

.paper {
 display: flex;
 justify-content: center;
 align-items: center;
}

.paper img {
  max-width: 100%;
}

.block {
  color: black;
  text-align: left;
  overflow: auto;
  /*height: 125px;*/
  padding-bottom: 3em;
  font-size: 10px;
  max-width: 400px;
}

.motto {
  left: 0px;
  position: fixed;
  color: black;
  transform: rotate(0.25turn);
  z-index: -100;
}

@keyframes App-logo-spin {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 900px) {
  .code {
    font-size: 3px;
    max-height: 200px;
  }
  .block {
    max-width: 100%;
  }
}


